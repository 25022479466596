.fs-10{
    font-size: 10px !important;
}

.fs-12{
    font-size: 12px !important;
}

.head{
    background-color: var(--main-color);
    padding: 2px;
    display: block;
    color: #fff;
}

.nocss{
    padding: 0px !important;
    height: auto !important;
    background-color: transparent !important;
}

.rbc-event{
    height: auto !important;
    background-color: transparent !important;
    padding: 0px !important;
}


.rbc-month-row{
    overflow: unset !important;
}

.block-event{
    padding: 5px 10px;
    border-radius: 12px;
}

.status-pending {
    background-color: #919191;
}

.status-cancel {
    background-color:#dc3232;
}

.status-approved {
    background-color: #61BA61;
}

.rbc-time-content{
    display: none !important;
}
.rbc-overflowing,
.rbc-time-header{
    height: 100% !important;
}

.rbc-time-header-gutter{
    display: none !important;
}