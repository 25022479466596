.leftSideBox{
    border-right: 1px solid #AFB8CF;
}

.hovereffect{
    transition: .5s all;
    border-radius: 5px;
    cursor: pointer;
}

.hovereffect:hover{
    background-color: #F1F1F1;
}

.overflow-left{
    height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
}

.over-w-0::-webkit-scrollbar
{
	width: 0;
}


.divder-color{
    background-color: #AFB8CF !important;
}

.pad-0-card .ant-card-body{
    padding: 0px !important;
}

.rightSideBox {
    padding: 0px 20px 20px 20px;
}

.incomingMsg{
    background-color: #E4E4E4;
    border-radius: 35px 35px 35px 0px;
    display: inline-block;
}

.outgoing{
    background-color: var(--main-color);
    border-radius: 35px 35px 0px 35px;
    display: inline-block;
}

.common-cs{
    padding: 18px;
}

.w-fitcontent{
    width: fit-content;
}

.mar-left-auto{
    margin-left: auto;
}
.overflow-right{
    height: 420px;
    overflow-y: auto;
    overflow-x: hidden;
}

.pad-12-5{
    padding: 12px 5px;
}
